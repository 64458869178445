.logo-image {
  height: 12.5vh;
  width: 53vh;
}
.content-section {
  background-color: var(--background-color) !important;
  //color: var(--primary-color);
  min-height: 81vh;
}
.landing-image {
  height: 69vh !important;
}

.border_left {
  border-left: 5px solid var(--primary-color);
  //border-left-radius: 10px;
}

p,
.change-text-color {
  color: var(--primary-color);
}

.sign-up-form {
  width: 70% !important;
  height: 65px !important;
}

.sign-up-btn {
  width: 70% !important;
  background: linear-gradient(
    var(--secondary-color),
    var(--primary-color)
  ) !important;
  border-radius: 5px;
  border-color: var(--primary-color) !important;
  height: 45px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.sign-in-form {
  height: 55px !important;
}

.sign-in-btn {
  width: 100%;
  background: linear-gradient(
    var(--secondary-color),
    var(--primary-color)
  ) !important;
  border-radius: 5px;
  border-color: var(--primary-color) !important;
  height: 45px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.response-img {
  height: 45vh;
}

.thank-you-text {
  font-weight: 600;
  font-size: 50px;
  color: var(--primary-color);
}
.disable-text {
  font-weight: 400;
  font-size: 20px;
  color: #bfbfbf;
}

.input-content-div {
  padding-left: 70px;
}

@media screen and (max-width: 600px) {
  .sign-up-form {
    width: 90% !important;
  }
  .sign-up-btn {
    width: 90% !important;
  }
  .logo-image {
    height: 10vh;
    width: 80vw;
  }
  .input-content-div {
    padding: 10px;
  }
  .response-img {
    height: 38vh;
  }
  .thank-you-text {
    font-weight: 600;
    font-size: 40px;
    color: var(--primary-color);
  }
  .disable-text {
    font-weight: 400;
    font-size: 18px;
    color: #bfbfbf;
  }
}
