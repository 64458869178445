@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

a,
a:hover {
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #833586;
  --secondary-color: #d13f96;
  --background-color: rgba(131, 53, 134, 0.1);
  --text-color: #637381;
  --white-color: #fff;
  --black-color: #000;
  --primary-font: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
  //overflow-x: hidden;
}

body {
  font-family: var(--primary-font) !important;
  font-size: 100% !important;
  font-weight: 400 !important;
  overflow-x: hidden !important;
  //background-color: var(--background-color) !important;
  min-height: 100vh !important;
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

::-webkit-scrollbar-track {
  background: var(--white-color);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

h1 {
  font-family: var(--primary-font) !important;
  font-size: 3rem !important;
}

h2 {
  font-family: var(--primary-font) !important;
  font-size: 2.5rem !important;
}

h4 {
  font-family: var(--primary-font) !important;
}

h5 {
  font-family: var(--primary-font) !important;
  font-size: 1rem !important;
}

p {
  font-family: var(--primary-font) !important;
}
