/* .quiz-image {
  height: 25vh;
  width: 100%;
} */

.border-down {
  height: 20px;
  background: linear-gradient(var(--primary-color), var(--secondary-color));
}

.main-section {
  background-color: var(--background-color) !important;
}

.home-text {
  color: var(--black-color) !important;
}

.information-card {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 10px 0px 10px 25px;
}

.quiz-card {
  background: var(--white-color);
  font-weight: 400;
  padding: 15px;
  border-radius: 5px;
}

.coupon-input {
  width: 60% !important;
}

.quiz-submit-btn {
  width: 40% !important;
  background: linear-gradient(
    var(--secondary-color),
    var(--primary-color)
  ) !important;
  border-radius: 5px;
  border-color: var(--primary-color) !important;
  height: 45px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.explore-btn {
  width: 30% !important;
  background: linear-gradient(
    var(--secondary-color),
    var(--primary-color)
  ) !important;
  border-radius: 5px;
  border-color: var(--primary-color) !important;
  //height: 45px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

@media screen and (max-width: 600px) {
  .explore-btn {
    width: 50% !important;
  }
  .quiz-submit-btn {
    width: 60% !important;
  }
  .form-control::placeholder {
    font-size: 14px !important;
  }
  .coupon-input {
    width: 100% !important;
  }
}
